import { Formik, FormikConfig } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { Button, Link, Flex } from 'rebass/styled-components';

import { Input, Label } from '@rebass/forms/styled-components';

import { useAuthenticateMutation } from '../graphql/types';
import { useGlobal } from '../hooks/useGlobal';
import Logo from './Logo';
import useAnalytics from '../hooks/useAnalytics';

type LoginFormValues = {
  username: string;
  password: string;
};

const Login: React.FC = () => {
  const { login } = useGlobal();
  const [authenticate, { loading }] = useAuthenticateMutation();
  const { analytics } = useAnalytics();

  const initialValues = { username: '', password: '' };

  const initialErrors = useMemo<
    FormikConfig<LoginFormValues>['initialErrors']
  >(() => {
    if (sessionStorage.getItem('session-expired')) {
      sessionStorage.removeItem('session-expired');
      return { username: 'Your session has expired' };
    }
  }, []);

  const handleSubmit = useCallback<FormikConfig<LoginFormValues>['onSubmit']>(
    async (values, actions) => {
      try {
        const response = await authenticate({
          variables: {
            email: values.username,
            password: values.password,
          },
        });

        login(response.data?.authenticate);

        const user = response.data?.authenticate?.user;
        if (user) {
          analytics?.identify(user.id, {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
          });
        }
        analytics?.track('Account Login');
      } catch (error) {
        actions.setErrors({ username: 'Invalid email or password' });
      }
    },
    [analytics, login, authenticate]
  );

  return (
    <Flex
      flex={1}
      sx={{ height: '100%', width: '100%', paddingBottom: '83px' }}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Flex
        flex="1"
        width={1}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        px={3}
      >
        <Formik<LoginFormValues>
          initialValues={initialValues}
          onSubmit={handleSubmit}
          initialErrors={initialErrors}
        >
          {({
            handleSubmit,
            isValid,
            isSubmitting,
            errors,
            handleChange,
            values,
          }) => (
            <>
              <Flex
                mb={3}
                mx={3}
                sx={{ maxWidth: 500, width: '100%', fill: '#999' }}
              >
                <Logo height={['30px', '30px', '30px']} />
              </Flex>
              <Flex
                bg="#2b2c2d"
                variant="card"
                flexDirection="column"
                sx={{ maxWidth: 500, width: '100%' }}
                p={3}
              >
                <form onSubmit={handleSubmit}>
                  <Label htmlFor="username">Email</Label>
                  <Input
                    name="username"
                    type="text"
                    onChange={handleChange}
                    value={values.username}
                    mb={3}
                  />
                  <Label htmlFor="password">Password</Label>
                  <Input
                    name="password"
                    type="password"
                    onChange={handleChange}
                    value={values.password}
                    mb={3}
                  />
                  <Flex justifyContent="space-between" alignItems="center">
                    <Button type="submit" disabled={isSubmitting || loading}>
                      Login
                    </Button>
                    <Link
                      href="https://kinema.com/recovery"
                      target="_blank"
                      color="#999"
                    >
                      Forgot your password?
                    </Link>
                  </Flex>
                </form>
                <Button
                  mt={3}
                  as="a"
                  href={`${process.env.REACT_APP_API_BASE_URL}/auth/google?app=offline`}
                >
                  Continue with Google
                </Button>
                <Button
                  mt={3}
                  as="a"
                  href={`${process.env.REACT_APP_API_BASE_URL}/auth/facebook?app=offline`}
                >
                  Continue with Facebook
                </Button>
              </Flex>
              {errors.username && (
                <Flex
                  bg="red"
                  my={3}
                  color="#fff"
                  p={3}
                  sx={{ maxWidth: 500, width: '100%', borderRadius: 2 }}
                >
                  {errors.username}
                </Flex>
              )}
            </>
          )}
        </Formik>
      </Flex>
    </Flex>
  );
};

export default Login;
